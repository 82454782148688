import React, { Component } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { compose, graphql } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import {
  Pane,
  Button,
  Heading,
  Card,
  Spinner,
  Icon,
  Paragraph,
  IconButton,
} from "evergreen-ui";
import { toast } from "react-toastify";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Helmet } from "react-helmet";

import WithAutoSave from "./withAutoSave";
import {
  WrappedSwitch,
  WrappedTextInput,
  WrappedTextInputField,
  WrappedSwitchField,
} from "./WrappedFields";
import {
  getDraftPreview,
  getDraftPreviewOptions,
  getAuthenticationState,
  getAuthenticationStateOptions,
  refreshProfilePicture,
  refreshProfilePictureOptions,
} from "../graphql";
import { renderWhileLoading, renderForError } from "../apollo";

const UPDATE_DRAFT_PREVIEW = gql`
  mutation UPDATE_DRAFT_PREVIEW(
    $hasRedirect: Boolean!
    $hasIntroduction: Boolean!
    $hasProfileHeader: Boolean!
    $redirectUrl: String!
    $introductionText: String!
    $buttons: [ButtonInput!]!
    $hasGallery: Boolean!
    $galleryTitle: String!
    $instagramUsername: String!
    $galleryId: String!
    $galleryRedirect: Boolean!
    $galleryDefaultRedirectUrl: String!
  ) {
    updatePreview(
      instagramUsername: $instagramUsername
      hasRedirect: $hasRedirect
      hasIntroduction: $hasIntroduction
      hasProfileHeader: $hasProfileHeader
      redirectUrl: $redirectUrl
      introductionText: $introductionText
      buttons: $buttons
      hasGallery: $hasGallery
      galleryTitle: $galleryTitle
      galleryId: $galleryId
      galleryRedirect: $galleryRedirect
      galleryDefaultRedirectUrl: $galleryDefaultRedirectUrl
    ) {
      id
      hasIntroduction
      hasRedirect
      hasProfileHeader
      introductionText
      buttons {
        url
        text
        enabled
      }
      hasGallery
      redirectUrl
      galleryTitle
      galleryId
      galleryRedirect
      galleryDefaultRedirectUrl
      updatedAt
    }
  }
`;

const BuildSchema = Yup.object().shape({
  hasProfileHeader: Yup.boolean(),
  hasIntroduction: Yup.boolean(),
  introductionText: Yup.string().when("hasIntroduction", {
    is: true,
    then: Yup.string().required().label("Introduction"),
  }),
  hasRedirect: Yup.boolean(),
  redirectUrl: Yup.string().when("hasRedirect", {
    is: true,
    then: Yup.string().url().required().label("Redirect URL"),
  }),
  hasGallery: Yup.boolean(),
  galleryTitle: Yup.string().when("hasGallery", {
    is: true,
    then: Yup.string().label("Gallery title"),
  }),
  galleryId: Yup.string().when("hasGallery", {
    is: true,
    then: Yup.string()
      .matches(
        /^[0-9a-fA-F]+$/,
        "Key is invalid. Must match key from account settings."
      )
      .required()
      .label("Account key"),
  }),
  galleryRedirect: Yup.boolean()
    .label("Gallery redirect")
    .when(["hasGallery", "hasRedirect"], {
      is: (hasGallery, hasRedirect) => hasGallery && !hasRedirect,
      otherwise: Yup.boolean().oneOf(
        [false],
        "Cannot be enabled if Redirect URL is also enabled."
      ),
    }),
  galleryDefaultRedirectUrl: Yup.string().when("hasGallery", {
    is: true,
    then: Yup.string()
      .url()
      .label("Default URL for Gallery items when clicked"),
  }),
  buttons: Yup.array().of(
    Yup.object().shape({
      url: Yup.string().required().label("URL"),
      text: Yup.string().required().max(128).label("Description"),
      enabled: Yup.boolean().label("Enabled"),
    })
  ),
});

class Build extends Component {
  state = {
    refreshingProfilePicture: false,
  };

  mutateServer = async (
    values,
    { setSubmitting },
    mutate,
    instagramUsername
  ) => {
    let variables = {
      hasRedirect: values.hasRedirect,
      redirectUrl: values.redirectUrl,
      hasProfileHeader: values.hasProfileHeader,
      hasIntroduction: values.hasIntroduction,
      introductionText: values.introductionText,
      buttons: [],
      hasGallery: values.hasGallery,
      galleryTitle: values.galleryTitle,
      galleryId: values.galleryId,
      galleryRedirect: values.galleryRedirect,
      galleryDefaultRedirectUrl: values.galleryDefaultRedirectUrl,
      instagramUsername,
    };

    for (let button of values.buttons) {
      if (
        typeof button.url === "string" &&
        button.url !== "" &&
        typeof button.text === "string" &&
        button.text !== ""
      ) {
        variables.buttons.push({
          url: button.url,
          text: button.text,
          enabled: button.enabled,
        });
      }
    }

    await mutate({ variables });
    setSubmitting(false);
  };

  onSortEnd = ({ oldIndex, newIndex }, move) => {
    move(oldIndex, newIndex);
  };

  refreshProfilePicture = async (instagramUsername) => {
    if (!this.state.refreshingProfilePicture) {
      try {
        this.setState({
          refreshingProfilePicture: true,
        });
        toast.info("Refreshing profile picture.. may take a minute");
        await this.props.refreshProfilePicture({
          variables: { instagramUsername },
        });
        this.setState({
          refreshingProfilePicture: false,
        });
        toast.success("Refreshed profile picture!");
      } catch (error) {
        toast.error("Couldn't refresh profile picture");
      }
    }
  };

  render() {
    let { draftPreview } = this.props;
    let {
      variables: { instagramUsername },
    } = draftPreview;
    let mutate = this.props.mutate;

    return (
      <>
        <Helmet>
          <title>Sked Link - Build</title>
        </Helmet>
        <Pane
          display="flex"
          flexDirection="column"
          borderRadius={3}
          marginTop={36}
          marginLeft={36}
          marginRight={36}
        >
          <Formik
            initialValues={draftPreview.draftPreview}
            validationSchema={BuildSchema}
            onSubmit={(values, options) =>
              this.mutateServer(values, options, mutate, instagramUsername)
            }
            isInitialValid={true}
            render={({ values, errors, touched, isSubmitting, isValid }) => (
              <Form>
                <Pane display="flex" paddingRight={8}>
                  <Pane flex={1}>
                    <Heading size={800}>Profile Header</Heading>
                    {/*<Paragraph marginTop={6} size={300} color="muted" fontFamily="ui">Click <span style={{ textDecoration: 'underline' }} onClick={() => this.refreshProfilePicture(instagramUsername)}>here</span> to refresh profile picture</Paragraph>*/}
                  </Pane>
                  <Pane>
                    <Field name="hasProfileHeader" render={WrappedSwitch} />
                    <ErrorMessage name="hasProfileHeader" component="div" />
                  </Pane>
                </Pane>
                <Pane display="flex" marginTop={48} paddingRight={8}>
                  <Pane flex={1}>
                    <Heading size={800}>Introduction</Heading>
                  </Pane>
                  <Pane>
                    <Field name="hasIntroduction" render={WrappedSwitch} />
                    <ErrorMessage name="hasIntroduction" component="div" />
                  </Pane>
                </Pane>
                <Card padding={8} marginTop={16} background="tint2">
                  <Field
                    type="text"
                    name="introductionText"
                    placeholder="Introduction text"
                    hint="What you'd like to show under your profile."
                    disabled={!values.hasIntroduction}
                    label="Introduction Text"
                    component={WrappedTextInputField}
                  />
                </Card>
                <Heading size={800} marginTop={48}>
                  Buttons
                </Heading>
                <FieldArray
                  name="buttons"
                  render={({
                    move,
                    swap,
                    push,
                    insert,
                    remove,
                    unshift,
                    pop,
                  }) => (
                    <SortableButtons
                      items={values.buttons}
                      push={push}
                      remove={remove}
                      onSortEnd={(props) => this.onSortEnd(props, move)}
                    />
                  )}
                />
                <Pane display="flex" paddingRight={8} marginTop={48}>
                  <Pane flex={1}>
                    <Heading size={800}>Gallery</Heading>
                  </Pane>
                  <Pane>
                    <Field name="hasGallery" render={WrappedSwitch} />
                    <ErrorMessage name="hasGallery" component="div" />
                  </Pane>
                </Pane>
                <Card padding={8} marginTop={16} background="tint2">
                  <Field
                    type="text"
                    name="galleryTitle"
                    placeholder="Explore"
                    label="Gallery Title"
                    hint="Make it something creative!"
                    disabled={!values.hasGallery}
                    component={WrappedTextInputField}
                  />
                  <Field
                    type="text"
                    name="galleryId"
                    placeholder="Account key"
                    label="Sked account key"
                    hint={
                      <Paragraph
                        marginTop={6}
                        size={300}
                        color="muted"
                        fontFamily="ui"
                      >
                        Get this from Sked in account settings by enabling
                        profile redirect URL.{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://help.skedsocial.com/en/articles/3811771-getting-started-with-sked-link?utm_source=skedlink&utm_medium=gallery_support"
                        >
                          See where to access it
                        </a>
                        .
                      </Paragraph>
                    }
                    disabled={!values.hasGallery}
                    component={WrappedTextInputField}
                  />
                  <Field
                    type="text"
                    name="galleryDefaultRedirectUrl"
                    placeholder="Default URL link for media"
                    label="Default URL for media"
                    hint={
                      <Paragraph
                        marginTop={6}
                        size={300}
                        color="muted"
                        fontFamily="ui"
                      >
                        Fallback URL for media that doesn't have redirect URL
                        set during scheduling.
                      </Paragraph>
                    }
                    disabled={!values.hasGallery}
                    component={WrappedTextInputField}
                  />
                  <Field
                    name="galleryRedirect"
                    label="Redirect to latest post"
                    hint={
                      "Enabling this will send all of your Sked Link traffic to the redirect URL of the latest photo in your feed which is set on Sked."
                    }
                    disabled={!values.hasGallery}
                    component={WrappedSwitchField}
                  />
                </Card>
                <Pane display="flex" marginTop={48} paddingRight={8}>
                  <Pane flex={1}>
                    <Heading size={800}>Redirect</Heading>
                  </Pane>
                  <Pane>
                    <Field name="hasRedirect" render={WrappedSwitch} />
                    <ErrorMessage name="hasRedirect" component="div" />
                  </Pane>
                </Pane>
                <Card
                  padding={8}
                  marginTop={16}
                  marginBottom={26}
                  background="tint2"
                >
                  <Field
                    type="text"
                    name="redirectUrl"
                    placeholder="https://url.com"
                    hint="Adding a URL here will send all of your Sked Link traffic to the one page. Your visitors will not see your buttons or gallery, they will just get sent immediately to this link."
                    disabled={!values.hasRedirect}
                    label="Redirect URL"
                    component={WrappedTextInputField}
                  />
                </Card>
                {isSubmitting ? (
                  <Button appearance="minimal" intent="success" type="button">
                    <Spinner size={24} paddingRight={4} /> saving..
                  </Button>
                ) : !isValid ? (
                  <Button appearance="minimal" intent="danger" type="button">
                    Cannot save with invalid input
                  </Button>
                ) : (
                  <Button appearance="primary" type="submit">
                    Save
                  </Button>
                )}
                <WithAutoSave />
              </Form>
            )}
          ></Formik>
        </Pane>
      </>
    );
  }
}

const SortableButton = SortableElement(({ value, remove }) => (
  <Pane
    key={value}
    marginBottom={4}
    style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
  >
    <Icon
      size={16}
      icon="drag-handle-vertical"
      style={{ cursor: "move", pointerEvents: "auto" }}
    />
    <Field
      name={`buttons[${value}].text`}
      placeholder="Label"
      marginLeft={4}
      component={WrappedTextInput}
    />
    <Field
      name={`buttons[${value}].url`}
      placeholder="https://url.com"
      marginLeft={4}
      component={WrappedTextInput}
    />
    <Field
      name={`buttons[${value}].enabled`}
      marginLeft={8}
      height={16}
      component={WrappedSwitch}
    />

    <IconButton
      type="button"
      appearance="minimal"
      marginLeft={8}
      icon="trash"
      intent="danger"
      onClick={() => remove(value)}
    ></IconButton>
  </Pane>
));

const SortableButtons = SortableContainer(({ items, push, remove }) => (
  <Card padding={8} marginTop={16} background="tint2" marginBottom={16}>
    {items.map((button, index) => {
      return (
        <SortableButton
          key={`item-${index}`}
          index={index}
          value={index}
          remove={remove}
        />
      );
    })}
    <Button
      type="button"
      appearance="minimal"
      iconBefore="add"
      onClick={() => push({ url: "", text: "", enabled: true })}
    >
      Add
    </Button>
  </Card>
));

export default compose(
  graphql(getAuthenticationState, getAuthenticationStateOptions),
  graphql(getDraftPreview, getDraftPreviewOptions),
  graphql(refreshProfilePicture, refreshProfilePictureOptions),
  graphql(UPDATE_DRAFT_PREVIEW),
  renderWhileLoading("authenticationState"),
  renderWhileLoading("draftPreview"),
  renderForError("authenticationState"),
  renderForError("draftPreview")
)(Build);
