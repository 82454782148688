import React, { Component } from "react";
import { Pane } from "evergreen-ui";
import { StickyContainer } from "react-sticky";
import Preview from "./Preview";
import { ErrorBoundary } from "./ErrorBoundary";

export default function withPreview(WrappedComponent) {
  return class extends Component {
    render() {
      return (
        <>
          <Pane display="flex" justifyContent="center">
            <Pane flex={6}>
              <WrappedComponent {...this.props} />
            </Pane>
            <StickyContainer>
              <Pane flex={4} alignItems="center" justifyContent="center">
                {/* Add an error boundary here as the preview should not EVER crash the whole dashboard */}
                <ErrorBoundary>
                  <Preview />
                </ErrorBoundary>
              </Pane>
            </StickyContainer>
          </Pane>
        </>
      );
    }
  };
}
