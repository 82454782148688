import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { graphql, compose } from "react-apollo";
import { Pane, Select, Icon, Tooltip } from "evergreen-ui";
import copy from "copy-to-clipboard";
import ReactGA from "react-ga";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import debounce from "debounce";
import "react-toastify/dist/ReactToastify.css";

import { auth } from "../firebase";
import {
    getAuthenticationState,
    getAuthenticationStateOptions,
    updateAuthenticationState,
    updateAuthenticationStateOptions,
    publishPreview,
    publishPreviewOptions,
    getDraftPreview,
    getDraftPreviewOptions,
} from "../graphql";
import { renderWhileLoading, renderForError } from "../apollo";

class Header extends Component {
    state = {
        publishing: false,
    };

    render() {
        let {
            authenticationState: {
                authenticationState: {
                    authenticated,
                    accounts,
                    selectedAccount,
                },
            },
            updateAuthenticationState,
            publishPreview,
        } = this.props;
        return (
            <Pane
                backgroundImage="linear-gradient(to right, #069cd1, #755ffc)"
                height="64px"
                padding={8}
            >
                <Pane
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    width="100%"
                    height="100%"
                >
                    {authenticated && accounts.length > 0 && (
                        <Pane>
                            <Link
                                to="/build"
                                className={`nav-button ${
                                    this.props.location.pathname === "/build"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                Build
                            </Link>
                            <Link
                                to="/design"
                                className={`nav-button ${
                                    this.props.location.pathname === "/design"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                Design
                            </Link>
                            <Link
                                to="/integrate"
                                className={`nav-button ${
                                    this.props.location.pathname ===
                                    "/integrate"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                Integrate
                            </Link>
                            <Link
                                to="/preview"
                                className={`nav-button ${
                                    this.props.location.pathname === "/preview"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                Preview
                            </Link>
                        </Pane>
                    )}
                    <div style={{ margin: "0 auto" }}></div>
                    <Pane>
                        {authenticated && accounts.length > 0 && (
                            <>
                                <div
                                    className="nav-pill"
                                    onClick={() => {
                                        copy(
                                            `https://sked.link/${selectedAccount}`,
                                        );
                                        toast.info("Copied to clipboard", {
                                            position: toast.POSITION.TOP_RIGHT,
                                        });
                                    }}
                                >
                                    {`https://sked.link/${selectedAccount}`}
                                    <Tooltip content="Copy to clipboard">
                                        <Icon
                                            icon="duplicate"
                                            paddingLeft="4px"
                                            size={16}
                                        />
                                    </Tooltip>
                                </div>
                                <div
                                    className={`nav-action ${
                                        this.state.publishing &&
                                        "nav-action-loading"
                                    }`}
                                    onClick={debounce(async () => {
                                        if (!this.state.publishing) {
                                            try {
                                                this.setState({
                                                    publishing: true,
                                                });
                                                let publish =
                                                    await publishPreview({
                                                        variables: {
                                                            instagramUsername:
                                                                selectedAccount,
                                                        },
                                                        refetchQueries: [
                                                            {
                                                                query: getDraftPreview,
                                                                variables: {
                                                                    instagramUsername:
                                                                        selectedAccount,
                                                                },
                                                            },
                                                        ],
                                                    });

                                                ReactGA.event({
                                                    category: "Preview",
                                                    action: "Publish",
                                                });

                                                this.setState({
                                                    publishing: false,
                                                    publishedAt: moment(),
                                                });
                                                toast.success("Published!", {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_RIGHT,
                                                });
                                                if (window._hsq) {
                                                    window._hsq.push([
                                                        "trackEvent",
                                                        {
                                                            id: "sked_link_published",
                                                        },
                                                    ]);
                                                }
                                                if (window.Intercom) {
                                                    window.Intercom(
                                                        "trackEvent",
                                                        "sked-link-published",
                                                    );
                                                }
                                                if (window._ctrack) {
                                                    window._ctrack.track(
                                                        "sked-link-published",
                                                    );
                                                }
                                            } catch (error) {
                                                this.setState({
                                                    publishing: false,
                                                    publishedErrorAt: moment(),
                                                });
                                                toast.error(
                                                    "There was an issue publishing, please try again later.",
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_RIGHT,
                                                    },
                                                );
                                            }
                                        }
                                    })}
                                >
                                    Publish
                                </div>
                                <Select
                                    onChange={(e) => {
                                        if (
                                            e.target.value === "Add Instagram"
                                        ) {
                                            this.props.history.push("/create");
                                        } else {
                                            updateAuthenticationState({
                                                variables: {
                                                    selectedAccount:
                                                        e.target.value,
                                                },
                                            });
                                        }
                                    }}
                                    value={selectedAccount}
                                    marginRight="8px"
                                >
                                    {accounts.map((account, index) => (
                                        <option key={index}>{account}</option>
                                    ))}
                                    <option>Add Instagram</option>
                                </Select>
                            </>
                        )}
                        {authenticated && (
                            <Link
                                to="#"
                                className="nav-button"
                                onClick={() => {
                                    auth.signOut();
                                }}
                            >
                                Logout
                            </Link>
                        )}
                        {!authenticated && (
                            <Link
                                to="/login"
                                className={`nav-button ${
                                    this.props.location.pathname === "/login"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                Login
                            </Link>
                        )}
                    </Pane>
                </Pane>
                <ToastContainer autoClose={2000} pauseOnHover={false} />
            </Pane>
        );
    }
}

export default compose(
    graphql(getAuthenticationState, getAuthenticationStateOptions),
    graphql(updateAuthenticationState, updateAuthenticationStateOptions),
    graphql(publishPreview, publishPreviewOptions),
    renderForError("authenticationState"),
    renderWhileLoading("authenticationState"),
)(withRouter(Header));
