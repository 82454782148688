import React, { PropsWithChildren, useEffect } from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { Router, useHistory, useLocation } from "react-router-dom";

import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import "autotrack";

import { auth } from "./firebase";
import history from "./history";
import { updateAuthenticationState, getDefaultAccount } from "./graphql";
import { client } from "./apollo";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.css";
import "./styles/Preview.css";
import App from "./components/App";

ReactGA.initialize("UA-46264715-8");
ReactGA.plugin.require("outboundLinkTracker");
ReactGA.plugin.require("pageVisibilityTracker");
ReactGA.plugin.require("maxScrollTracker");
ReactGA.pageview(window.location.pathname + window.location.search);

function GAListener(props: PropsWithChildren<any>) {
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        sendPageView(location);
        history.listen(sendPageView);
    }, []);

    function sendPageView(location: { pathname: string }) {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
        ReactPixel.pageView();
        const hsqInstance = (window as any)._hsq;

        if (hsqInstance) {
            hsqInstance.push(["setPath", location.pathname]);
            hsqInstance.push(["trackPageView"]);
        }
        const intercomInstance = (window as any).Intercom;
        if (intercomInstance) {
            intercomInstance("update");
        }
    }

    return props.children;
}

auth.onAuthStateChanged(async (user) => {
    const intercomInstance = (window as any).Intercom;
    const ctrackInstance = (window as any)._ctrack;
    const hsqInstance = (window as any)._hsq;

    if (user) {
        let { email, displayName, emailVerified, uid } = user;

        ReactPixel.init("311864732777197", { firebaseId: uid } as any);
        ReactPixel.pageView();
        ReactGA.set({ userId: uid });

        if (hsqInstance) {
            hsqInstance.push([
                "identify",
                {
                    email,
                    sked_link: true,
                },
            ]);
        }

        if (intercomInstance) {
            let intercomHash = window.localStorage.getItem("intercom-hash");
            let intercomId = window.localStorage.getItem("intercom-id");

            if (intercomHash) {
                intercomInstance("boot", {
                    app_id: "e9h91cip",
                    email: email,
                    user_id: intercomId,
                    user_hash: intercomHash,
                });
            }
        }

        if (ctrackInstance) {
            ctrackInstance.identify({
                userId: uid, // Your Id of the user
                email: email, // Email of the user
            });
        }

        let defaultAccount = await client.query({
            query: getDefaultAccount,
        });
        let { instagrams } = defaultAccount.data;

        let newAuthenticationState = {
            authenticated: true,
            user: {
                email,
                displayName,
                emailVerified,
                uid,
            },
            accounts: [],
            selectedAccount: "",
        };

        if (instagrams && instagrams.length > 0) {
            newAuthenticationState.selectedAccount =
                defaultAccount.data.instagrams[0].username;
            newAuthenticationState.accounts =
                defaultAccount.data.instagrams.map(
                    (instagram: { username?: string }) => instagram.username,
                );
        }

        let result = await client.mutate({
            mutation: updateAuthenticationState,
            variables: newAuthenticationState,
        });

        const token = await auth.currentUser?.getIdToken(true);
    } else {
        await client.resetStore();
        ReactPixel.init("311864732777197");
        ReactPixel.pageView();
        if (hsqInstance) {
            hsqInstance.push(["revokeCookieConsent"]);
        }
        if (intercomInstance) {
            intercomInstance("boot", {
                app_id: "e9h91cip",
            });
        }
        //history.push('/');
    }

    ReactDOM.render(
        <Router history={history}>
            <GAListener>
                <ApolloProvider client={client}>
                    <App />
                </ApolloProvider>
            </GAListener>
        </Router>,
        document.getElementById("root"),
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
