import * as React from "react";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }
    render() {
        if (this.state.hasError) {
            return (
                <h1>
                    Something went wrong rendering your link. Please check your
                    settings.
                </h1>
            );
        }
        return this.props.children;
    }
}
