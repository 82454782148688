import { initializeApp, getApps } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const productionConfig = {
    name: "production",
    config: {
        apiKey: "AIzaSyDjyxbJf6IMYdUuHCdBqCsP2F9b3um2Mbk",
        authDomain: "sked-link-production.firebaseapp.com",
        databaseURL: "https://sked-link-production.firebaseio.com",
        projectId: "sked-link-production",
        storageBucket: "sked-link-production.appspot.com",
        messagingSenderId: "608841692012",
    },
};
const stagingConfig = {
    name: "staging",
    config: {
        apiKey: "AIzaSyBG5VgBqUDvv3mbPXLT-iILjsT5h6K-MBs",
        authDomain: "sked-link-87d5e.firebaseapp.com",
        databaseURL: "https://sked-link-87d5e.firebaseio.com",
        projectId: "sked-link-87d5e",
        storageBucket: "sked-link-87d5e.appspot.com",
        messagingSenderId: "4325095297",
    },
};

const configs = [stagingConfig, productionConfig];

const selectedConfig =
    configs.find(
        (config) => config.name === process.env.VITE_FIREBASE_CONFIG_NAME,
    ) || productionConfig;

let firebaseApp = getApps().at(0);
if (!firebaseApp) {
    firebaseApp = initializeApp(selectedConfig.config);
}

const auth = getAuth(firebaseApp);

export { auth };
